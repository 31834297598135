import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';

import { useRef } from 'react';


import axios from 'axios';

import Box from '@mui/material/Box';

import * as Icons from '@mui/icons-material/';

import * as Api from 'app-api';

import './LA_InputSelector.css';

import { Badge, Tooltip } from '@mantine/core';

interface LA_InputSelectorProps extends LY.LY_InputBaseProps {
  workspaceId: number | undefined;

  linkedWorkspaceId?: number | undefined;

  model: LY.DataItemModel;
  column: Api.ListColumn;
  lookupTypes?: Api.SystemLookupType[];
  dropDownData?: Record<string, Array<Record<string, any>>>;
  forceReadOnly?: boolean;
  disableNameClick?: boolean;
  onChange?: (model: LY.DataItemModel | undefined, event?: React.ChangeEvent<any> | undefined) => void;
  onClick?: (model: LY.DataItemModel | undefined, event: React.MouseEvent<any> | undefined) => void;
  onNameClick?: (model: LY.DataItemModel | undefined, event: React.MouseEvent<any> | undefined) => void;
  forceUpdateState?: (state?: App.ListState | undefined) => void;
  style?: React.CSSProperties;
  isForList?: boolean;
  isForFilters?: boolean;

  maxDisplayLength?: number;

}

export const LA_InputSelector: React.FC<LA_InputSelectorProps> = ((
  {
    onChange,
    onClick,
    onNameClick,
    model,
    dropDownData,
    style,
    forceReadOnly = false,
    isForList = false,
    isForFilters = false,
    value,
    ...props

  }) => {

  const forceUpdate = LIB.useForceUpdate();
  //const [model, setModel] = React.useState<LY.DataItemModel>(props.model);

  const col = props.column;
  if (!model.column && model)
    model.column = col;

  /*   React.useEffect(() => {
  
       console.log('LA_InputSelector useEffect model: ', model);
  
    //   setModel(props.model);
   
  
      if (!forceReadOnly && LY.ListColumnManager.isCalculatedColumn(col) || col?.hasAutoGenerate) {
        forceReadOnly = true;
      }
  
    }, [ props.model ]); */

  React.useEffect(() => {

    //console.log('LA_InputSelector useEffect model: ', model);

    //setModel(props.model); 

    if (!forceReadOnly && LY.ListColumnManager.isCalculatedColumn(col) || col?.hasAutoGenerate) {
      forceReadOnly = true;
    }

  }, [ props.column ]);


  function getValue() {

    let val = LY.ListColumnManager.getCalculatedValue(col, model?.row, value);

  }

  function getInputRenderer() {


    if(isForFilters)
      console.log('LA_InputSelector getInputRenderer col name:', col?.name);

    var type: any = 'text';
    if (!LIB.Common.isNullOrEmpty(col?.listColumnClientType))
      type = col?.listColumnClientType;

    var standardInputTypes = ['text', 'number', 'email', 'password', 'tel', 'url', 'date', 'time', 
                              'datetime-local', 'month', 'week', 'color'];
    if(!standardInputTypes.includes(type))
      type = 'text';                          

    var val = model?.value || value;

    if(!isForFilters)
      val = LY.ListColumnManager.getCalculatedValue(col, model?.row, value);

    if(isForFilters){
      forceReadOnly = false;
      if(type.includes('datetime'))
        type = 'date';
    }

    /*    if(col?.name=='name'){
       
          console.log('LA_InputSelector getInputRenderer val:', val);
          console.log('LA_InputSelector getInputRenderer value:', value);
          console.log('LA_InputSelector getInputRenderer model.value:', model?.value);

        }  */

      
    if (forceReadOnly) {

      var returnVal = false;
      if (col?.listColumnTypeId == App.ListColumnTypeEnum.DateAndTime) {
        val = LIB.DateHelper.getFormattedDateWithAMPM(val);

        returnVal = true;
      }

      if (col?.listColumnTypeId == App.ListColumnTypeEnum.Date) {
        val = LIB.DateHelper.getFormattedShortDate(val);
        returnVal = true;
      }

      // if(returnVal)
      //   return <span className='LY_ListColumnCellReadOnly'>{val}</span>;

      if (!val)
        type = 'text';

    }

    var result = (

      <LY.LY_Input
        key={`formItem_${col.name}_${model?.rowId}`}
        name={col.name}
        type={type}
        label={col.displayName}
        readOnly={forceReadOnly}
        onChange={onChange}
        {...props}
        model={model}
        value={val}
      />

    );


    return result;

  }

  function getListInputRenderer(readOnly: boolean | undefined) {



    var val = LY.ListColumnManager.getCalculatedValue(col, model?.row, model?.value);


    if (readOnly || forceReadOnly) {


/*       if (col?.name == 'total') {

        console.log('LA_InputSelector getListInputRenderer total:', val);
      }
 */
      if (col?.listColumnTypeId == App.ListColumnTypeEnum.DateAndTime)
        val = LIB.DateHelper.getFormattedDateWithAMPM(val);

      if (col?.listColumnTypeId == App.ListColumnTypeEnum.Date)
        val = LIB.DateHelper.getFormattedShortDate(val);


      if (col?.listColumnTypeId == App.ListColumnTypeEnum.LongText) {
        val = LIB.StringHelper.maxLength(val, 20);
      }

      return <span className='LY_ListColumnCellReadOnly'>{val}</span>;

    }
    //console.log('LA_InputSelector getInputRenderer getCalculatedValue:', col?.name);

    if (col?.name == 'tax') {

      console.log('LA_InputSelector getListInputRenderer tax:', val);

      console.log('LA_InputSelector getListInputRenderer tax model?.value:', model?.value);

    }

    var type: any = 'text';
    if (!LIB.Common.isNullOrEmpty(col?.listColumnClientType))
      type = col?.listColumnClientType;

      if(col?.name=='_ly_date_created'){
        console.log('LY_TextInput getInputRenderer Date Created value:', model?.value);
        console.log('LY_TextInput getInputRenderer Date Created model:',model);
  
      } 
  
    var result = <LY.LY_TextInput
      viewId={model?.viewId}
      rowId={model?.rowId}
      name={col?.name}
      value={model?.value}
      type={type}
      originalValue={model?.originalValue}
      inputStyle={{ borderColor: 'transparent' }}
      style={style}
      onChange={onChange}
      onEnterBlur={true}
      model={model}
    />


    return result;

  }

  function getColorRenderer(readOnly: boolean | undefined) {



    if (readOnly || forceReadOnly)
      return <span className='LY_ListColumnCellReadOnly'>{model?.value}</span>;

    var result = <LY.LY_ColorInput

      name={col?.name}
      value={model?.value || ''}
      inputInnerClassName={isForList ? 'LY_ColorInputInputForTable' : undefined}
      style={style}
      onColorChange={(m, val) => onChange?.(m!, undefined)}
      // onEnterBlur={true}
      {...props}
      model={model}

    />


    return result;

  }

  function getDropDownRenderer(readOnly: boolean | undefined) {



    if (readOnly || forceReadOnly || !col)
      return <div className='LY_ListColumnCellReadOnly'>{model?.value}</div>;


    var data: any[] = [];

    var displayProperty = '';
    var valueProperty = '';
    var isLookupType = false;
    var isAutoComplete = false;

    if (col.isRelationship) {
      //todo var fkState = state.relationshipLists?.find(x => x.listId === col.fkListId);
      //todo data = fkState?.records || [];
      displayProperty = col.fkDisplayListColumnName || col.fkListColumnName || '';
      valueProperty = col.fkListColumnName || '';
      isAutoComplete = true;
      /*    console.log('LA_InputSelector getDropDownRenderer fkState: ',fkState);
         console.log('LA_InputSelector getDropDownRenderer fkState data: ',data);
    */
    }
    else {
      data = props.lookupTypes?.filter(x => x.list_id === model.listId &&
        x.column_id === col.listColumnId) || [];
      displayProperty = 'name';
      valueProperty = 'id';
      isLookupType = true;

    }


    if (LIB.Common.isNullOrEmpty(valueProperty))
      return <span className='LY_ListColumnCellReadOnly'>{model?.value}</span>;

    var selectedItem = data.find(x => x[ valueProperty ] === model?.value);

    var dstyle = { ...style };
    if (isForList)
      dstyle.border = 0;

    var result = <LY.LY_DropDown //TriggerButton
      key={'LY_DropDown_' + model?.rowId + '_' + model?.column?.name}
      name={col?.name}
      value={model?.value}
      displayProperty={displayProperty}
      valueProperty={valueProperty}
      selectedItem={selectedItem}
      selectedItemBadgeColor={selectedItem?.text_color}

      /*  onTriggerClick={(e,m)=>{
         console.log('LA_InputSelector getDropDownRenderer onTriggerClick m: ',m);
          if(context && m){
           context.triggerDropDown(m,e);
          }
          forceUpdate();
  
       }} */
      data={data}
      optionsContainerClassName='_LY_DropDownBaseViewEditDropdownTableCell'
      // originalValue={model?.originalValue}
      // inputStyle={{ borderColor: 'transparent' }}
      style={dstyle}
      hideLabel={isForList}
      isAutoCompleteStyle={isAutoComplete}
      //optionsContainerStyle={{ minHeight: 400}}
      // onChange={onChange}
      //   onEnterBlur={true}

      onValueChange={(m: LY.DataItemModel, item: any) => {
        console.log('LA_InputSelector getDropDownRenderer onChange2 m: ', m);

         selectedItem = data?.find(x => x[ valueProperty ] === m?.value);

/*         console.log('LA_InputSelector getDropDownRenderer onChange2 item: ', item);
 */
        forceUpdate();

        if (onChange) {

     /*      console.log('LA_InputSelector getDropDownRenderer onValueChange onChange m: ', m);
          console.log('LA_InputSelector getDropDownRenderer onValueChange onChange item: ', m); */

          onChange(m, item);
        }


      }
      }
      {...props}
      model={model}

    />


    return result;

  }


  function geDynamicDropDownRenderer(readOnly: boolean | undefined) {

    // console.log('LA_InputSelector geDynamicDropDownRenderer readOnly:', readOnly);


    /*    if (readOnly || forceReadOnly || !col)
         return <div className='LY_ListColumnCellReadOnly'>{model?.value}</div>;
    */


    var valueProperty = col.name || '';
    var displayProperty = col.fkDisplayListColumnName || '';
    var isLookupType = false;

    // console.log('LA_InputSelector geDynamicDropDownRenderer valueProperty:', valueProperty);
    //console.log('LA_InputSelector geDynamicDropDownRenderer displayProperty:', displayProperty);

    //console.log('LA_InputSelector geDynamicDropDownRenderer dropDownData:', dropDownData);
    // console.log('LA_InputSelector geDynamicDropDownRenderer col.fkListColumnId:', col.fkListColumnId);

    var data: any[] = [];

    if (dropDownData && col.listColumnId)
      data = dropDownData[ col.listColumnId ] || [];

    //console.log('LA_InputSelector geDynamicDropDownRenderer data:', data);

    var selectedItem = data.find(x => x[ valueProperty ] === model?.value);

    /*     if (col?.name == 'company') {
         console.log('LA_InputSelector geDynamicDropDownRenderer selectedItem:', selectedItem);
        console.log('LA_InputSelector geDynamicDropDownRenderer selectedItem model?.value:', model?.value);
         console.log('LA_InputSelector geDynamicDropDownRenderer linkedWorkspaceId:', props.linkedWorkspaceId);
    
    
          console.log('LA_InputSelector geDynamicDropDownRenderer:', readOnly, col?.name);
    
     
        } */

    var dstyle = { ...style };
    if (isForList) {
      dstyle.border = 0;
      if (readOnly || forceReadOnly)
        dstyle.paddingLeft = 5;
    }


    var result = <LY.LY_DynamicDropDown
      key={'LY_DynamicDropDown_' + model?.rowId + '_' + model?.column?.name}
      linkedWorkspaceId={props.linkedWorkspaceId}

      listId={col.fkListId || ''}
      displayColumnId={col.fkDisplayListColumnId || ''}
      valueColumnId={col.fkListColumnId || ''}
      viewId={col.fkListViewId || -1}


      name={col?.name}
      value={model?.value}
      displayProperty={displayProperty}
      valueProperty={valueProperty}
      selectedItem={selectedItem}
      selectedItemBadgeColor={selectedItem?.text_color}
      data={data}
      onDataLoaded={(result: Api.ListDropDownDataResult) => {
        var loadedData = result?.records || [];
        //console.log('LA_InputSelector getDropDownRenderer onDataLoaded loadedData: ', loadedData);
      }
      }
      optionsContainerClassName='_LY_DropDownBaseViewEditDropdownTableCell'
      style={dstyle}
      hideLabel={isForList}
      // placeholder=''//{'Select ' + col.displayName}
      onValueChange={(m: LY.DataItemModel, val: any, inSelectedItem: any) => {
        console.log('LA_InputSelector geDynamicDropDownRenderer onChange m: ', m);

        var foundSelectedItem = data?.find(x => x[ valueProperty ] === m?.value);
        /*   if(!foundSelectedItem)
            data.push(inSelectedItem);
   */
        selectedItem = inSelectedItem;
        //todo update data set onDataLoaded

         /*        console.log('LA_InputSelector geDynamicDropDownRenderer selectedItem:', selectedItem);
                console.log('LA_InputSelector geDynamicDropDownRenderer valueProperty:', valueProperty);
                console.log('LA_InputSelector geDynamicDropDownRenderer inSelectedItem:', inSelectedItem);
                console.log('LA_InputSelector geDynamicDropDownRenderer val:', val);   */
        forceUpdate();
        if (onChange){
          /* console.log('LA_InputSelector geDynamicDropDownRenderer onChange selectedItem:', selectedItem);
          console.log('LA_InputSelector geDynamicDropDownRenderer onChange val:', val);
          console.log('LA_InputSelector geDynamicDropDownRenderer onChange m.value:', m?.value);
 */
          onChange(m, val);

        }
      }
      }
      {...props}
      model={model}
      readOnly={readOnly || forceReadOnly}
      readOnlyShowAsText={true}

    />


    return result;

  }

  function getHoverTextRenderer(readOnly: boolean | undefined) {


    var tooltipColName = `_ly_join_tooltip_dla_fsc_codes_title`;

    return <Tooltip
      position="right"
      label={model?.row[ tooltipColName ]}
      offset={{ mainAxis: 0 }}
      withArrow
      arrowSize={6}
    >
      <LY.LY_Badge value={model?.value} />
    </Tooltip>


  }





  function onLocalChange(m: LY.DataItemModel | undefined, e: any) {

    // console.log('LA_InputSelector LY_TagsInput onLocalChange m: ',m);
    console.log('LA_InputSelector onLocalChange model: ', model);
    // console.log('LY_NameColumnInput getViewMode model.value3: ',model?.value);

    if (!m)
      return

    model.value = m.value;

    //console.log('LY_NameColumnInput getViewMode model.value4: ',model?.value);
    //console.log('LY_NameColumnInput getViewMode model.value5: ',m?.value);


    if (onChange)
      onChange(m, e);

    //forceUpdate();
  }



  function getNameColumnRenderer(readOnly: boolean | undefined) {

    /*     if(col?.name=='name'){
          console.log('LA_InputSelector getNameColumnRenderer col?.fkDisplayTypeId:', col?.fkDisplayTypeId);
          console.log('LA_InputSelector getNameColumnRenderer isForList:', isForList);
    
        } */

    if (isForList && (col?.isRelationship == true || col?.name == "name") &&
      col?.fkDisplayTypeId == LY.ListColumnRelDisplayTypeEnum.LinkPopup) {

      //    console.log('LA_InputSelector getNameColumnRenderer got here!!:');

      return getRelationshipLinkRenderer(readOnly);

    }

    if (!isForList) {

      return getInputRenderer();
    }




    var result = <LY.LY_NameColumnInput

      name={col?.name}


      style={style}
      onNameChange={onLocalChange}
      onNameClick={onNameClick}
      readOnly={forceReadOnly}
      {...props}
      model={model}
      // label='Name'
      //labelPlacement='top'
      value={value}
    />

    return result;

  }

  function getCheckboxRenderer(readOnly: boolean | undefined) {



    if (LY.ListColumnManager.isCalculatedColumn(col)) {
      forceReadOnly = true;
    }

    var result = <LY.LY_CheckBox

      name={col?.name}
      checked={value}
      model={model}
      style={style}
      onChange={onLocalChange}
      inputContainerBodyClassName={isForList ? 'LY_CheckBoxInputContainerBody' : 'LY_CheckBoxInputContainerBodyForm'}
      hideLabel={isForList}
      readOnly={forceReadOnly}
      {...props}
    //  labelPlacement='left'
    />

    return result;

  }

  function getTagsInputRenderer(readOnly: boolean | undefined) {



    if (LY.ListColumnManager.isCalculatedColumn(col)) {
      forceReadOnly = true;
    }

    var result = <LY.LY_TagsInput

      name={col?.name}
      style={style}
      onChange={onLocalChange}
      readOnly={forceReadOnly}
      {...props}
      inputContainerWrapperClassName={isForList ? '' : 'LY_TagsInputContainerWrapperForm'}
      value={value}
      model={model}
    />

    return result;

  }

  function getSwitchRenderer(readOnly: boolean | undefined) {




    if (LY.ListColumnManager.isCalculatedColumn(col)) {
      forceReadOnly = true;
    }

    var result = <LY.LY_Switch

      name={col?.name}
      value={model?.value}


      style={style}
      onChange={onLocalChange}
      inputContainerClassName={isForList ? 'LY_SwitchInputContainer' : 'LY_SwitchInputContainerForm'}

      readOnly={forceReadOnly}
      {...props}
      model={model}
    //labelPlacement='left'

    />

    return result;

  }


  function getWebsiteRenderer(readOnly: boolean | undefined) {


    let isCalculatedColumn = LY.ListColumnManager.isCalculatedColumn(col);
    if (isCalculatedColumn) {
      forceReadOnly = true;
    }

    //  console.log('LA_InputSelector getWebsiteRenderer col:', col);
    //console.log('LA_InputSelector getWebsiteRenderer forceReadOnly:', forceReadOnly);
    // console.log('LA_InputSelector getWebsiteRenderer isCalculatedColumn:', isCalculatedColumn);
    //  console.log('LA_InputSelector getWebsiteRenderer model:', model);
    // console.log('LA_InputSelector getWebsiteRenderer value:', model?.value);


    var result = <LY.LY_WebsiteInput

      name={col?.name}
      // value={model?.value}

      style={style}
      onValueChange={onLocalChange}

      readOnly={forceReadOnly}
      {...props}
      model={model}
      maxDisplayLength={props.maxDisplayLength}
      inputViewEditContainerClassName={isForList ? '' : '_LY_InputBaseViewEditContainerForm'}
      isForList={isForList}
    />


    return result;

  }


  function getRelationshipLinkRenderer(readOnly: boolean | undefined) {




    var result = <LY.LY_Relationship
      // viewId={viewId}
      // rowId={rowId}
      name={col?.name}
      value={model?.value}

      onItemClick={onClick}
      style={style}
      readOnly={forceReadOnly}

      model={model}
      inputContainerClassName={isForList ? '' : 'LY_RelationshipInputContainerForm'}
      {...props}

    />

    return result;

  }


  function getStatusDropDownRenderer(readOnly: boolean | undefined) {

    // console.log('LA_InputSelector getStatusDropDownRenderer workspaceId:', props.workspaceId);
    // console.log('LA_InputSelector getStatusDropDownRenderer model.column:', model?.column);


    var displayProperty = '';
    var valueProperty: any = '';
    var isLookupType = false;
    var isAutoComplete = false;


    var data = props.lookupTypes?.filter(x => x.list_id === model.listId &&
      x.column_id === col.listColumnId) || [];


    displayProperty = 'name';
    valueProperty = 'id';
    isLookupType = true;



    if (LIB.Common.isNullOrEmpty(valueProperty))
      return <span className='LY_ListColumnCellReadOnly'>{model?.value}</span>;

    // @ts-ignore
    var selectedItem = data.find(x => x[ valueProperty ] === model?.value);

    var dstyle = { ...style };
    if (isForList)
      dstyle.border = 0;



    var result = <LY.LY_StatusDropDown
      key={'LY_StatusDropDown_' + model?.rowId + '_' + model?.column?.name}
      workspaceId={props.workspaceId}
      name={col?.name}
      label={isForList ? undefined : col.displayName}
      valueProperty={valueProperty}
      model={model}
      data={data}
      value={model?.value}
      hideLabel={props.hideLabel}
      inputClassName={props.inputClassName}
      inputContainerClassName={props.inputContainerClassName}
      style={style}
      // onChange={(item?: Api.SystemLookupType) => setSelectedStatus(item)}
      //onEditSaveSuccess={onEditSaveSuccess}

      onChange={(m: LY.DataItemModel, item?: Api.SystemLookupType) => {
        console.log('LA_InputSelector LY_StatusDropDown onChange m: ', m);

        forceUpdate();
        if (onChange)
          onChange(m)//, item);
      }
      }

      onEditSaveSuccess={(m: LY.DataItemModel, options?: Api.SystemLookupType[], selected?: Api.SystemLookupType) => {
        // console.log('LA_InputSelector LY_StatusDropDown onEditSaveSuccess m: ', m);
        console.log('LA_InputSelector LY_StatusDropDown onEditSaveSuccess options: ', options);
        console.log('LA_InputSelector LY_StatusDropDown onEditSaveSuccess data: ', data);

        if (!options || !props.lookupTypes)
          return;

        //lets first delete items already existing in lookupTypes
        var otherLookups = props.lookupTypes?.filter(x => x.column_id != col.listColumnId) || [];

        var combinedOptions = [ ...otherLookups, ...options ];
        //now let's sort the items


        data = options || [];
        forceUpdate();

        var newListState: any = {
          workspace: {
            lookupTypes: combinedOptions
          }
        }

        console.log('LA_InputSelector LY_StatusDropDown onEditSaveSuccess combinedOptions: ', combinedOptions);

        if (props.forceUpdateState) {
          //   console.log('LA_InputSelector LY_StatusDropDown onEditSaveSuccess forceUpdateState newListState: ', newListState);

          props.forceUpdateState(newListState);

        }
      }
      }

    />


    return result;

  }

  function getStaticDropDownRenderer(readOnly: boolean | undefined) {

    // console.log('LA_InputSelector getStaticDropDownRenderer workspaceId:', props.workspaceId);
    // console.log('LA_InputSelector getStaticDropDownRenderer model.column:', model?.column);


    var displayProperty = '';
    var valueProperty: any = '';
    var isLookupType = false;
    var isAutoComplete = false;


    var data = props.lookupTypes?.filter(x => x.list_id === model.listId &&
      x.column_id === col.listColumnId) || [];


    displayProperty = 'name';
    valueProperty = 'id';
    isLookupType = true;



    if (LIB.Common.isNullOrEmpty(valueProperty))
      return <span className='LY_ListColumnCellReadOnly'>{model?.value}</span>;

    // @ts-ignore
    var selectedItems: any[] = [];

    var dstyle = { ...style };
    if (isForList)
      dstyle.border = 0;

    var isMultiple = (col?.listColumnTypeId == App.ListColumnTypeEnum.MultiSelectDropdown);

    if (model?.value) {
      if (isMultiple) {
        selectedItems = data.filter(x => model?.value?.includes(x[ valueProperty as keyof Api.SystemLookupType ]));
      }
      else
        selectedItems = [ data.find(x => x.id === model?.value) ];
    }


    var result = <LY.LY_StaticDropDown
      key={'LY_StaticDropDown_' + model?.rowId + '_' + model?.column?.name}
      workspaceId={props.workspaceId}
      name={col?.name}
      label={isForList ? undefined : col.displayName}
      valueProperty={valueProperty}
      model={model}
      data={data}
      selectedValues={selectedItems}
      hideLabel={props.hideLabel}
      inputClassName={props.inputClassName}
      inputContainerClassName={props.inputContainerClassName}
      style={dstyle}
      allowMultiSelect={isMultiple}

      onChange={(m: LY.DataItemModel, item?: Api.SystemLookupType) => {
        console.log('LA_InputSelector LY_StaticDropDown onChange m: ', m);

        forceUpdate();
        if (onChange)
          onChange(m)//, item);
      }
      }

      onEditSaveSuccess={(m: LY.DataItemModel, options?: Api.SystemLookupType[], selectedOptions?: Api.SystemLookupType[]) => {
        // console.log('LA_InputSelector LY_StatusDropDown onEditSaveSuccess m: ', m);
        console.log('LA_InputSelector LY_StaticDropDown onEditSaveSuccess options: ', options);
        console.log('LA_InputSelector LY_StaticDropDown onEditSaveSuccess data: ', data);

        if (!options || !props.lookupTypes)
          return;

        //lets first delete items already existing in lookupTypes
        var otherLookups = props.lookupTypes?.filter(x => x.column_id != col.listColumnId) || [];

        var combinedOptions = [ ...otherLookups, ...options ];
        //now let's sort the items


        data = options || [];
        forceUpdate();

        var newListState: any = {
          workspace: {
            lookupTypes: combinedOptions
          }
        }

        console.log('LA_InputSelector LY_StaticDropDown onEditSaveSuccess combinedOptions: ', combinedOptions);

        if (props.forceUpdateState) {
          //   console.log('LA_InputSelector LY_StatusDropDown onEditSaveSuccess forceUpdateState newListState: ', newListState);

          props.forceUpdateState(newListState);

        }
      }
      }

    />



    return result;

  }

  function render() {

    //console.log('LA_InputSelector render:', col?.name);


    /*  if (model?.isEditMode) {
       console.log('LA_InputSelector col', col);
       console.log('LA_InputSelector model', model);
 
     } */


    var readOnly = props.readOnly;
    var result: any;

    /* if(col.name=='product_service'){
      console.log('LA_InputSelector id readOnly1:', readOnly);
    
    } */


    if (!forceReadOnly && LY.ListColumnManager.isCalculatedColumn(col) || col?.hasAutoGenerate) {
      forceReadOnly = true;
    }


    if (col?.hasAutoGenerate)
      readOnly = true;

    else if (col?.listColumnTypeId == App.ListColumnTypeEnum.CalculatedNumber ||
      col?.listColumnTypeId == App.ListColumnTypeEnum.CalculatedValue) {
      readOnly = true;

    }

    if (readOnly)
      forceReadOnly = true;

    /*     if(col?.name=='diibs_nsn'){
        console.log('LA_InputSelector col?.isRelationship:', col?.isRelationship);
        console.log('LA_InputSelector col?.listColumnTypeId:',col?.listColumnTypeId);
  
      }
    */

/* 
    if (col?.name == 'total') {

      console.log('LA_InputSelector getInputRenderer getCalculatedValue:', col?.name);
    }
 */
    if (col?.name === 'name' && !props.disableNameClick && !isForFilters) {
      result = getNameColumnRenderer(readOnly);

    }
    else if (col?.listColumnTypeId == App.ListColumnTypeEnum.Website && !isForFilters) {
    //   console.log('LA_InputSelector Website:', col?.name);

      result = getWebsiteRenderer(readOnly);
    }
    else if (col?.listColumnTypeId == App.ListColumnTypeEnum.Status) {
      result = getStatusDropDownRenderer(readOnly);
    }
    else if ((col?.listColumnTypeId == App.ListColumnTypeEnum.Dropdown ||
      col?.listColumnTypeId == App.ListColumnTypeEnum.MultiSelectDropdown)
      && !col?.isRelationship) {
      // result = getDropDownRenderer(readOnly);
      result = getStaticDropDownRenderer(readOnly);
    }
    else if (col?.listColumnTypeId == App.ListColumnTypeEnum.Dropdown && col?.isRelationship) {
      result = geDynamicDropDownRenderer(readOnly);
    }
    //might eventually change to User specific dropdown 
    else if (col?.listColumnTypeId == App.ListColumnTypeEnum.User && col?.isRelationship) {
      result = geDynamicDropDownRenderer(readOnly);
    }
    else if (col?.listColumnTypeId == App.ListColumnTypeEnum.HoverText && !isForFilters) {
      result = getHoverTextRenderer(readOnly);
    }
    else if (col?.listColumnTypeId == App.ListColumnTypeEnum.Relationship &&
      !LIB.Common.isNullOrEmpty(col?.fkListId) && !col.fkIsMultiple  && !isForFilters
    ) {
      result = getRelationshipLinkRenderer(readOnly);
    }
    else if (col?.isRelationship == true && !LIB.Common.isNullOrEmpty(col?.fkListId) && !col.fkIsMultiple
      && col?.fkDisplayTypeId == LY.ListColumnRelDisplayTypeEnum.LinkPopup && !isForFilters
    ) {
      result = getRelationshipLinkRenderer(readOnly);
    }
    else if (col?.listColumnTypeId == App.ListColumnTypeEnum.Color) {
      result = getColorRenderer(readOnly);
    }
    else if (col?.listColumnTypeId == App.ListColumnTypeEnum.CheckBox && !isForFilters) {
      result = getCheckboxRenderer(readOnly);
    }
    else if (col?.listColumnTypeId == App.ListColumnTypeEnum.Switch && !isForFilters) {
      result = getSwitchRenderer(readOnly);
    }
    else if (col?.listColumnTypeId == App.ListColumnTypeEnum.Tags) {
      result = getTagsInputRenderer(readOnly);
    }
    else if (isForList) {


      result = getListInputRenderer(readOnly);
    }
    else
      result = getInputRenderer();



    return result;

  }


  function renderWithMemo() {

    return React.useMemo(() => render(), [ model?.value ]);
  }
  //return renderWithMemo();
  return render();
});
