import React from 'react';
import './LY_InputBase.css';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';



export const LY_InputBase: React.FC<LY.LY_InputBaseProps> = ({
  label,
  name,
  key,
  labelPlacement = 'top',
  errorPlacement = 'bottom',
  helpTextPlacement = 'top',
  error,
  children,
  labelClassName,
  errorClassName,
  inputContainerClassName,
  withStar = false,
  hidden = false,
  helpText,
  hideLabel,
  isRequired,
  readOnly=false,
  style,
  containerGap = ".5rem",
  tooltip,
  model,
  ...props

}) => {
  // Combine default classes with any custom classes provided
  const combinedLabelClassName = `mantine-InputWrapper-label mantine-TextInput-label LY_InputBaseLabel ${labelClassName || ''}`;
  const combinedErrorClassName = `error ${errorClassName || ''}`;
  const combinedInputContainerClassName = `LY_inputContainer ${inputContainerClassName || ''}`;

  const combinedHelpTextClassName = `mantine-InputWrapper-description mantine-TextInput-description LY_InputBaseHelpText ${labelClassName || ''}`;

  //console.log('LY_InputBase labelPlacement', labelPlacement);

  function getHelpText(showLabel: boolean) {
    if(hideLabel)
      return;

    if (!showLabel || !helpText )
      return; 
     
    var result = <p
      key={`LY_InputBaseLabelHelpText_key_${key || ''}`}
      className={combinedHelpTextClassName}>{helpText}</p>;
    return result;
  }

  function getChildrenWithTooltip(view:any){

   // console.log('getChildrenWithTooltip name', name);

    var ttip = tooltip || model?.column?.helpText;

/*     if( name=='yes_or_no'){
      console.log('getChildrenWithTooltip yes_or_no model', model?.column?.name);
      console.log('getChildrenWithTooltip yes_or_no ttip:', ttip);
      console.log('getChildrenWithTooltip yes_or_no isValidElement view:', React.isValidElement(view));

    } */
 
    
    if (ttip && React.isValidElement(view)) {
    //  if( name=='yes_or_no')
     // console.log('getChildrenWithTooltip got in ttip:', ttip);

      return <MT.Tooltip 
      key={`LY_InputBaseTooltip_key_${key || ''}`}
      className={'LY_InputBaseTooltip'+name}
      label={ttip} style={{maxWidth: '220px', textWrap: 'wrap'}}
        // w={220}
        withArrow
        transitionProps={{ duration: 200 }}
        position='bottom'
         events={{ hover: true, focus: true, touch: false }}
         withinPortal={true}
      >  
        {view}
      </MT.Tooltip>
    } 

    if( name=='yes_or_no')
    console.log('getChildrenWithTooltip got in ttip end:', ttip);

    return view;
 
  }


  function getRowView() {

    return <>
      {getHelpText([ 'top' ].includes(helpTextPlacement))}

      <div className={combinedInputContainerClassName}

        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: containerGap,
          ...style,
          ...props.inputContainerStyle
        }}>

        {getLabel([ 'left' ].includes(labelPlacement))}
        {/* {children} */}
        {getChildrenWithTooltip(children)}
        {getLabel([ 'right' ].includes(labelPlacement))}
      </div>

      {getHelpText([ 'bottom' ].includes(helpTextPlacement))}
      {error && <span className={combinedErrorClassName}>{error}</span>}

    </>
  }

  function getColumnView() {

    //if(labelPlacement !== 'top' || labelPlacement !== 'bottom')
    //   return null;

    return <div className={combinedInputContainerClassName}
      key={`LY_InputBaseContainer_key_${key || ''}`}
      style={{
        display: 'flex',
        flexDirection: 'column',  ...style, ...props.inputContainerStyle
      }}>
      {getLabel([ 'top' ].includes(labelPlacement))}
      {getHelpText([ 'top' ].includes(helpTextPlacement))}
     {/*  {children} */}
      {getChildrenWithTooltip(children)}
      {getLabel([ 'bottom' ].includes(labelPlacement))}
      {getHelpText([ 'bottom' ].includes(helpTextPlacement))}
      {error && <span className={combinedErrorClassName}>{error}</span>}
    </div>

  }

  function getLabel(showLabel: boolean) {
    if (!showLabel || !label || hideLabel)
      return;
    var withStarSpan: any = undefined;
    if ((withStar || (withStar == undefined || isRequired)) && !readOnly) {
      withStarSpan = <span
        key={`LY_InputBaseLabelStar_key_${key || ''}`}
        className="mantine-InputWrapper-required LY_InputBaseLabelStar"
        aria-hidden="true"> *</span>
    }

    var result = <label
      key={`LY_InputBaseLabel_key_${key || ''}`}
      className={combinedLabelClassName}>{label} {withStarSpan}</label>;

    return result;
  }

  function render() {

    if (hidden)
      return null;

    var view: any;
    if (labelPlacement == 'left' || labelPlacement == 'right')
      view = getRowView();
    else
      view = getColumnView();


    /* if (tooltip) {
      return <MT.Tooltip label={tooltip} style={{maxWidth: '220px', textWrap: 'wrap'}}
       // w={220}
        withArrow
        transitionProps={{ duration: 200 }}
        position='bottom'
        events={{ hover: true, focus: true, touch: false }}
        withinPortal={true}
      >
        {view}
      </MT.Tooltip>
    } */

   // var result = getChildrenWithTooltip(view);
    return view;
  }

  return render();

};

