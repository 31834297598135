import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class AppUser {

  userId: number;
  firstName: string;
  lastName: string;
  companyName: string;
  companyId: number;
  fullName: string;
  displayName: string;
  jobTitle: string;
  email: string;
  phone: string;
  //  userRoles:App.UserRoleType[];
  userRoleIds: number[];
  // isAdmin:boolean;
  realUserIsAdmin: boolean;
  isImpersonating:boolean;
  //adUserId: string;
  token: string;
  isEmailConfirmed: boolean = false;

  modulePermissions: App.AppModulePermission[] | undefined;


  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;

  enableProductUpdatesEmail: boolean = false;
  isProfileDetailsComplete: boolean = false;

  company:Api.Company;

  isManager: boolean = false;


  constructor() {

    // this.userRoles=[];
    this.userRoleIds = [];
    //this.isAdmin=false;
    this.realUserIsAdmin = false;
  }

  public get isAuthenticated(): boolean {
    var ret = (this.userId > 0);
    return ret;
  }

  public get isAdmin(): boolean {
    var ret = (this.hasRole(App.UserRoleTypeEnum.Admin));
    return ret;
  }


  public get isAuthenticatedAndEmailConfirmed(): boolean {
    if (this.isAuthenticated && this.isEmailConfirmed)
      return true;
    else
      return false;
  }

  public reset() {
    var user: AppUser = new App.AppUser();
    this.setData(user);
  }

  public setData(user: AppUser) {
    if (LIB.Common.isNullOrEmpty(user))
      return;
    this.userId = user.userId;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.fullName = user.fullName;
    this.email = user.email;
    this.company = user.company;//Api.Company.fromJS(user.company);
    this.companyId = user.companyId;
    this.companyName = user.companyName;
    this.userRoleIds = user.userRoleIds;
    //this.isAdmin = user.isAdmin;
    this.realUserIsAdmin = user.realUserIsAdmin;
    this.modulePermissions = user.modulePermissions;
    this.displayName = user.displayName;
    this.jobTitle = user.jobTitle;
    this.isImpersonating = user.isImpersonating;
    this.token = user.token;
    this.isEmailConfirmed = user.isEmailConfirmed;
    this.address = user.address;
    this.city = user.city;
    this.state = user.state;
    this.zipCode = user.zipCode;
    this.country = user.country;
    this.phone = user.phone;
    this.enableProductUpdatesEmail = user.enableProductUpdatesEmail;
    this.isProfileDetailsComplete = user.isProfileDetailsComplete;
    this.isManager = user.isManager;



  }

  public getModule(type: App.AppModuleTypeEnum): App.AppModulePermission //| undefined
  {
    var empty = new App.AppModulePermission();
    if (this.modulePermissions == undefined || LIB.Common.isNullOrEmpty(this.modulePermissions)
      || this.modulePermissions.length < 1) {
      return empty;
    }

    var found = this.modulePermissions.find(x => x.appModuleTypeId === type);
    if (found !== undefined && !LIB.Common.isNullOrEmpty(found))
      return found;

    return empty;

  }

  public hasModule(type: App.AppModuleTypeEnum): boolean {

    var result = this.getModule(type);
    return result.canRead;
    /*  if(result===undefined)
       return false;

     return true; */

  }


  public hasRole(role: App.UserRoleTypeEnum): boolean {

    var result =  (this.userRoleIds.indexOf(role) > -1 || this.userRoleIds.indexOf(App.UserRoleTypeEnum.Admin) >-1);
    //console.log('hasRole: ',role,result);

    return result;
  }

  public hasRoles(roles: App.UserRoleTypeEnum[]): boolean {
    //console.log('hasRoles: ',roles);

    for (let item of roles) {
      var result = this.hasRole(item);
      if (!result)
        return false;
    }

    return true;
  }

  public getAppUserVm():Api.AppUserVm{

    var ret = new Api.AppUserVm();
    ret = LIB.ObjectHelper.setData(this,ret);
    console.log('getAppUserVm ret',ret);
    return ret;
  }

}