import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, Stack, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Api from 'app-api';
import { useParams, useLocation } from 'react-router';

import { Badge, Tooltip, Tabs, rem } from '@mantine/core';
import * as Icons from '@tabler/icons-react';


import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import ResizeHandle from 'Components/Layouts/MainSplitPage/ResizeHandle';
import { DetailRightPanel } from 'Pages/Detail/DetailRightPanel/DetailRightPanel';

import './ListPage.css';
import { ListKanbanView } from './ListKanbanView/ListKanbanView';



const ListPage: React.FC<App.ListState> = (props) => {


  var validationManager: App.ValidationManager = new App.ValidationManager();
  var params = useParams();
  var location = useLocation();

  //  const globalUIState = props.globalUIState;
  var globalUIState = App.AppBase.getGlobalState();

  //var workspaceVm = props.workspaceVm;
  var workspaceVm = globalUIState?.workspaceVm;
  const selectedWorkspace = workspaceVm?.selectedWorkspace;
  const workspaceId = selectedWorkspace?.workspaceId;

  var workspaceItem = workspaceVm?.selectedItem;
  //const vm = props.initialData;
  const vm = props.vm;
  const currentUser = App.AppBase.currentUser;

  const lookupTypes = globalUIState.initialData.lookupTypes;

  const forceUpdate = LIB.useForceUpdate();

  const [ tab, setTab ] = React.useState<number | undefined>(workspaceItem?.defaultViewId);
  const [ sm, setSm ] = React.useState<Api.ListGetDataSm>(new Api.ListGetDataSm());

  const [ listStates, setListStates ] = React.useState<Api.ListState[]>([]);
  const [ pageLoadDone, setPageLoadDone ] = React.useState<boolean>(false);


  const iconStyle = { width: rem(16), height: rem(16) };
  const lockIconStyle = { width: rem(14), height: rem(14) };



  /*   var initalState = new App.ListState();
    initalState.view = props.view;
    initalState.list = props.list;
    //initalState.selectedView = initalState.list?.listViews? initalState.list.listViews[0]: undefined;
    const [ localState, setLocalState ] = React.useState<App.ListState>(initalState);
  
    */


  React.useEffect(() => {
    console.log('ListPage useEffect');
    var state = props;
    // var vm = state.vm;


    var id: any = params.id;
    sm.viewId = Number(id);
    sm.linkedWorkspaceId = Number(params.linked);
    sm.workspaceId = Number(params.w);

    console.log('ListPage useEffect sm.viewId', sm.viewId);

    //  console.log('ListPage useEffect viewId', sm.viewId);
    //console.log('ListPage useEffect Linked workspaceItem', workspaceItem);

    if (workspaceItem?.defaultViewId != sm.viewId && !isNaN(id) && !pageLoadDone) {
      // if (workspaceItem?.workspaceId != sm.viewId && !isNaN(id)){

      var checkWorkspaceId = sm.linkedWorkspaceId || workspaceId;

      console.log('ListPage useEffect Linked itemWorkspace', checkWorkspaceId);

      var itemWorkspace = workspaceVm.allWorkspaces.find(x => x.workspaceId == checkWorkspaceId);

      console.log('ListPage useEffect Linked itemWorkspace', itemWorkspace);

      // let's find the workspace item to select
      let newSelectedItem = itemWorkspace?.workspaceItems?.find(x => x.listViews?.find(b => b.listViewId == sm.viewId) != undefined);
      workspaceVm.selectedItem = newSelectedItem;
      workspaceItem = newSelectedItem;

      console.log('ListPage useEffect Linked newSelectedItem', newSelectedItem);

      App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, { ...workspaceVm, disableOnSelectRouteToPage: true });
    }



    if (id == '' || id == undefined) {
      id = workspaceItem?.defaultViewId;
    }

    console.log('ListPage useEffect workspaceItem', workspaceItem);

    console.log('ListPage useEffect id2', id);

    setPageLoadDone(true);

    if (!id)
      return;

    sm.viewId = Number(id);

    setTab(sm.viewId);

    console.log('ListPage useEffect id3', id);
    var workspace = App.AppBase.currentWorkspace;

    sm.filter = undefined;// new Api.QueryFilter();

    setSm({ ...sm });

    console.log('ListPage useEffect sm', sm);
    console.log('ListPage useEffect tab', tab);
    console.log('ListPage useEffect id', params.id);
    console.log('ListPage useEffect workspaceId', workspaceId);

    //console.log('ListPage useEffect state', state);
    // console.log('ListPage useEffect workspace', workspace);

    //forceUpdate();



  }, [ params.id ]);





  function getSelectedListViewTabContent(view: Api.ListViewSm) {

    //console.log('ListPage getSelectedListViewTabContent view', view);

    //if not selected tab, return null
    if (!view || sm.viewId != view.listViewId)
      return null;

    console.log('ListPage getSelectedListViewTabContent show List listViewId: ', view.listViewId);

    var leftPinnedColumns = [ 'name' ];

    if (view.listId == "cd99e4a3-08cc-4d01-b579-8e81e25da999")//DLA RFQs
      leftPinnedColumns.push('nsn');

      var listViews = workspaceItem?.listViews || [];

    var result = <LY.ListTableView
      viewId={view.listViewId}
      workspaceId={workspaceId}
      linkedWorkspaceId={sm.linkedWorkspaceId}
      showFilters={true}
      onListDataLoaded={(newState) => {
        console.log('ListTableView onListDataLoaded', newState);

      }}

      leftPinnedColumns={leftPinnedColumns}

      lookupTypes={lookupTypes}

      onSaveAsNewViewSuccess={(newView: Api.ListViewSm) => {

        console.log('LY_FiltersModule onSaveAsNewViewSuccess newView:', newView);
        listViews.push(newView);
        onTabChange(newView);

   
 
      }}
    />
    return result;
  }

  function getViewTabs(): any[] {

    var listViews = workspaceItem?.listViews;

    console.log('ListPage getViewTabs listViews', listViews);

    if (!listViews || !Array.isArray(listViews))
      return [];

    var result: any[] = [];

    var count = 1;
    for (var view of listViews!) {

      var tabId = view.listViewId?.toString() || '-1';

      //  console.log('ListPage getViewTabs tabId', tabId);

      var tab = <Tabs.Tab
        key={'tab' + view.listViewId}
        value={tabId}

        classNames={{ tabSection: "LY_ListPageViewTabSection" }}
        className='LY_ListPageViewTab'
        /*   rightSection={
              <MT.CloseButton
              className='LY_TabsContextMenuButton'
              //onClick={() => context.onDeleteSort(index)}
              icon={<Icons.IconDots style={iconStyle} />}
            />
          }  */
        rightSection={
          <div className='LY_TabsContextMenuButtonContainer'>
            <Icons.IconDots className='LY_TabsContextMenuButton' style={iconStyle} />
          </div>
        }

        leftSection={<Icons.IconDatabase style={iconStyle} />}>

        <div key={'div' + workspaceItem?.listId} style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
          {view?.name}
          {view?.isLocked &&
            <Icons.IconLock style={lockIconStyle} />
          }
        </div>

      </Tabs.Tab>
      result.push(tab);
      count++;
    }

    return result;
  }

  function getViewTabContents(): any[] {

    var listViews = workspaceItem?.listViews;

    console.log('ListPage getViewTabs listViews', listViews);
    if (!listViews || !Array.isArray(listViews))
      return [];

    var result: any[] = [];

    var count = 1;
    for (var view of listViews) {

      var tabId = view.listViewId?.toString() || '-1';

      var panel = <Tabs.Panel
        key={'tabPanel' + view.listViewId}
        value={tabId} className='ListPageTabPanel'>

        {getSelectedListViewTabContent(view)}

      </Tabs.Panel>

      result.push(panel);
      count++;
    }

    return result;
  }


 

  function onTabChange(tab: Api.ListViewSm) {

    console.log('ListPageTabs onChange tab', tab);

    App.RouteManager.routeToList(tab.listViewId, workspaceId, sm.linkedWorkspaceId);

  }

 
  function getMainPanelContent() {


    //var title = view?.name || list?.name;
    var title = workspaceItem?.name;

    if (workspaceItem?.isFolder) {

      return <>
        <h1 className='ListPageTitle'>{title}</h1>

        <div>
          Folder content coming soon...
        </div>
      </>;
    }

    //console.log('getMainPanelContent props', props);

    return <div>
      <h1 className='ListPageTitle'>{title}</h1>


      {getTabs()}


    </div>

  }


  function getTabs() {

    console.log('ListPage getTabs selectedTab', tab);
    var listId = workspaceItem?.listId;

    if (!listId)
      return null;

    var listViews = workspaceItem?.listViews || [];

    return <LY.LY_ListViewTabsModule
      name='ListViewTabs'

      onTabChange={onTabChange}
      selectedViewId={tab}
      listId={listId}
      workspaceId={workspaceId!}
      linkedWorkspaceId={sm.linkedWorkspaceId}
      contentPanels={getViewTabContents()}
      data={listViews}
      onTabOrderChanged={(newTabs: Api.ListViewSm[]) => {

        if (workspaceItem?.listViews)
          workspaceItem.listViews = newTabs;
      }}
      onNewViewSuccess={(view: Api.ListViewSm) => {
        console.log('ListPage onNewViewSuccess view', view);
     //   listViews.push(view);
        onTabChange(view);

      }}
      onDeleteViewSuccess={(view: Api.ListViewSm) => {

        console.log('ListPage onDeleteViewSuccess view', view);

        if (workspaceItem?.listViews) {
          workspaceItem.listViews = listViews.filter(t => t.listViewId !== view.listViewId) || [];

          if (workspaceItem.listViews.length > 0){
            var gotoFirstView = workspaceItem.listViews[0];
            console.log('ListPage onDeleteViewSuccess FirstView', gotoFirstView);

            onTabChange(gotoFirstView);

          }

        }
      }}

    />

    /* 
        //var tabs = getViewTabs();
     
        return <Tabs 
        key={'tabs'+workspaceItem?.listId}
        defaultValue={tab}  value={params.id?tab:undefined}
        className='ListPageTabsContainer'
        onChange={onTabChange}
     
        classNames={{ list: "LY_ListPageTabsList" }}
    
      >
        <Tabs.List>
    
          {tabs}
     
        </Tabs.List>
    
      
        {getViewTabContents()}
    
     
    
      </Tabs> */

  }

  function render() {

    console.log('ListPage render');

    if (!globalUIState.isInitialDataLoaded)
      return null;

    return <App.MainSplitPage
      //pageTitle={<span>{view?.name}</span>}
      //state={vm}
      hasDataLoaded={globalUIState.isInitialDataLoaded}
      //loading={(state.isServiceCallPending)}// && !vm.isActionInProgress)}
      errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      showLeftMenu={true}
      mainPageContainerClassName='MainSplitPageContainerForList'
      mainPanelsClassName='MainSplitPageMainPanelsForList'
    //showRightPanel={vm.isDetailScreenOpen}
    >

      {getMainPanelContent()}


    </App.MainSplitPage>


  }

  return render();


};


/* export default connect(
  (state: App.AppState) => ({ ...state.globalData })
)(ListPage as any); */
export default connect(
  (state: App.AppState) => ({ ...state.listState })
)(ListPage as any);
